// admin/topics/
export const marketDataRoutes = [
  {
    path: '',
    name: 'TopicTopics',
    component: () => import('../topic/Topic.page.vue'),
  },
  {
    path: 'select-topic',
    name: 'TopicSelectTopic',
    component: () => import('./pages/topic/Topic.page.vue'),
  },
  {
    path: 'market-volume-data',
    name: 'TopicMarketVolumeData',
    component: () =>
      import('./pages/market-volume-data/MarketVolumeData.page.vue'),
  },
  {
    path: 'companies',
    name: 'TopicCompanies',
    component: () => import('./pages/company/Company.page.vue'),
  },
  {
    path: 'companies/new',
    name: 'CreateCompanyByTopicPage',
    component: () => import('./pages/company/pages/CompanyById.page.vue'),
  },
  {
    path: 'companies/edit/:companyId',
    name: 'EditCompanyByTopicPage',
    component: () => import('./pages/company/pages/CompanyById.page.vue'),
  },
  {
    path: 'documents',
    name: 'TopicDocuments',
    component: () => import('../document/TopicDocument.page.vue'),
  },
  {
    path: 'documents/new',
    name: 'NewTopicDocument',
    component: () => import('./pages/document/pages/NewDocument.page.vue'),
  },
  {
    path: 'documents/edit/:documentId',
    name: 'EditTopicDocument',
    component: () => import('./pages/document/pages/EditDocument.page.vue'),
    props: (route) => ({
      fromCreated: route.query.fromCreated === 'true' // if prevpage was from createdDocument Send prop true
    })
  },

  // programs
  // THIS IS FOR EDIT PROGRAMS
  {
    path: 'program/overview/:programId',
    name: 'ProgramOverviewDocument',
    component: ()=> import('./pages/program/pages/ProgramOverview.page.vue'),
    props: (route) => ({
      fromCreated: route.query.fromCreated === 'true' // if prevpage was from createdDocument Send prop true
    })
  },
  {
    path: 'program/new/',
    name:'NewProgramDocument',
    component:()=> import('./pages/program/pages/NewProgram.page.vue')
  },
  
  // output
  {
    path:'output',
    name:'MarketData-OutputPage',
    component:() => import('./pages/output/Output.page.vue')
  },
  {
    path: 'output/forecastData/:topicId',
    name:'ProgramOutput',
    component:()=> import('./pages/output/pages/ProgramOutput.page.vue')
  },

  // TODO DELETE ./pages/program/pages/ProgramOutputPreview.page.vue'
  // {
  //   // maybe this one is not gonna use 
  //   path: 'output/preview',
  //   name:'outputProgramPreview',
  //   component:()=> import('./pages/program/pages/ProgramOutputPreview.page.vue')
  // },
    //programpercountry
  {
    path: 'output/programpercountry/:topicId',
    name:'programpercountry',
    component:()=> import('./pages/output/pages/ProgramsPerCountry.page.vue')
  },
    // contractpercompany
  {
    path: 'output/contractpercompany/:topicId',
    name:'contractpercompany',
    component:()=> import('./pages/output/pages/ContractPerCompany.page.vue')
  },

  // companyProfiles
  {
    path: 'output/companyProfiles/:topicId',
    name:'companyProfiles',
    component:() => import('./pages/output/pages/CompanyProfiles.page.vue')
  },

  //  CountryData
  {
    path:'output/countryDataOutput/:topicId',
    name: 'CountryDataOutput',
    component: () => import('./pages/output/pages/CountryDataOutput.page.vue')
  },
  // ExportData
  {
    path:'output/exportData/:topicId',
    name:'ExportDataOutput',
    component: () => import('./pages/output/pages/ExportTo.page.vue')
  }

];
