<template>
  <Navbar />
  <div class="global-container">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import Navbar from './shared/components/Navbar.vue';
import Footer from './shared/components/Footer.vue';

export default {
  name: 'App',
  components: { Navbar, Footer },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  // font-size: 62.5%;
  font-size: 80.5%;
}

.global-container {
  padding-top: 3.8rem;
  min-height: 100vh;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.2rem 0.5rem !important;
}

//  Button with icon 
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 15% !important;
  height: 0.1rem !important;
}
.p-button.p-component.p-button-outlined{
  padding: 0rem 0.5rem  !important;
}

//  inputText
.p-inputtext{
  padding: 0.2rem 0.2rem !important;
}

.p-multiselect .p-multiselect-label{
  padding: 0.2rem 0.2rem !important;
}

//  divider 
.p-divider.p-divider-horizontal {
    margin: 0.02rem 0 !important;
}

// 
.p-dropdown-item-group{
  padding: 0 !important;
}


// Calendar YearPicker !display title 
.p-datepicker-decade{
    display: none;
}

// ql-snow ql-editor  
.ql-snow .ql-editor h4{
    font-size: 1.2em !important;
    font-weight: bold !important;
}

.ql-snow .ql-editor h5{
    font-size: 1.1em !important;
    font-weight: bold !important;
}


</style>
