import {
  getCompanies,
  getFilteredCompanies,
  getCompanyByCompanyId,
  getCompaniesByTopicId,
  getCompaniesWithTotalsByTopicId,
  getCompanyTypes,
  editCompany,
  createCompany,
  deleteCompany,
  getContractsPerCompany,
  getCompanyAnalysesType,
  addCompanyAnalysesItems,
  editCompanyAnalysesItems,
  deleteCompanyAnalysesItems,

  
} from '../../modules/admin/company/services/company.service';

const companyModule = {
  state: () => ({
    companies: [],
    company: [],
    companiesPerTopic: [],
    companyTypes: [],
    contractPerCompany: [], // ContractPerCompany
    companyIdForCreateDocument: null,
    companyIdForCreateProgram: null,

    //companySort
    companySort: {
      sortField: "name",
      sortOrder: 1,
      firstPagValue: null,
      rowsPagValue: null,
      priority: true,
    },

    // TopicCompanySort
    topicCompanySort: {
      sortField: "name",
      sortOrder: 1,
      firstPagValue: null,
      rowsPagValue: null,
      showAllCompanies: false,
    },

    // SWOT
    companyAnalysesType: [],


  }),
  getters: {
    obtainCompanies(state) {
      return state.companies;
    },
    obtainCompany(state) {
      return state.company;
    },
    obtainCompanyPerTopic(state) {
      return state.companiesPerTopic;
    },
    obtainCompanyTypes(state) {
      return state.companyTypes;
    },
    obtainContractPerCompany(state) { //obtain ContractPerCompany
      return state.contractPerCompany
    },
    obtainCompanySort(state) {
      return state.companySort
    },
    obtainTopicCompanySort(state) {
      return state.topicCompanySort
    },
    obtainCompanyForCreateDocument(state) { // For create a document wit company Selected
      return state.companyIdForCreateDocument
    },
    obtainCompanyForCreateProgram(state) {
      return state.companyIdForCreateProgram
    },
    // SWOT
    obtainSwotCompanyAnalysesType(state) {
      return state.companyAnalysesType
    }
  },
  mutations: {
    setCompanies(state, companies) {
      state.companies = companies;
    },
    setCompany(state, company) {
      state.company = company
    },
    setCompaniesPerTopic(state, company) {
      state.companiesPerTopic = company;
    },
    setCompanyTypes(state, companyTypes) {
      state.companyTypes = companyTypes;
    },
    setContractPerCompany(state, contract) {
      state.contractPerCompany = contract
    },
    cleanContractPerCompany(state){
      state.contractPerCompany = [] 
    },

    addCompany(state, company) {
      state.companies.push(company);
    },
    // maybe not nesessary
    addCompanyPerTopic(state, company) {
      state.companiesPerTopic.push(company);
    },
    updateCompany(state, company) {
      state.companies = [
        ...state.companies.filter((t) => t.companyId !== company.companyId),
        company,
      ];
    },
    deleteCompany(state, companyId) {
      state.companies = [
        ...state.companies.filter((t) => t.companyId !== companyId),
      ];
    },
    deleteCompanyPerTopic(state, companyId) {
      state.companiesPerTopic = [
        ...state.companiesPerTopic.filter((t) => t.companyId !== companyId),
      ];
    },
    //CompanySort 
    updateCompanySort(state, companySort) {
      state.companySort = companySort
    },

    // TopicCompanySort
    updateTopicCompanySort(state, topicCompanySort) {
      state.topicCompanySort = topicCompanySort;
    },

    // For create a document with company Selected
    updateCompanyForCreateDocument(state, companyId) {
      state.companyIdForCreateDocument = companyId
    },

    resetUpdateCompanyForCreateDocument(state) {
      state.companyIdForCreateDocument = null
    },

    // FOR CREATE A PROGRAM WITH MANUFACTURED SELECTED
    updateCompanyForCreateProgram(state, companyId) {
      console.log('set To CompanyIdForCreateProgram');
      state.companyIdForCreateProgram = companyId;
    },

    resetUpdateCompanyForCreateProgram(state) {
      state.companyIdForCreateProgram = null
    },

    // SWOT
    setSwotCompanyAnalysesType(state, companyAnalysesType) {
      state.companyAnalysesType = companyAnalysesType;
    },

    cleanSwotCompanyAnalysesType(state) {
      state.companyAnalysesType = [];
    },

    addCompanyAnalysesItems(state, { companyAnalysesItem, companyAnalysesTypeId }) {
      let companyAnalysesItems = state.companyAnalysesType.find(item => item.companyAnalysesTypeId === companyAnalysesTypeId)
      companyAnalysesItems.companyAnalysesItems.push(companyAnalysesItem);
    },

    editCompanyAnalysesItems(state, { companyAnalysesItem }){
      // console.log('state',state.companyAnalysesType);
      // console.log('companyAnalysesItem',companyAnalysesItem);
      const newCompanyAnalysesItem = companyAnalysesItem.data;

      const index = state.companyAnalysesType.findIndex(item => item.companyAnalysesItems.some(i => i.companyAnalysesItemId === newCompanyAnalysesItem.companyAnalysesItemId))

      if(index !== -1){
        const itemIndex = state.companyAnalysesType[index].companyAnalysesItems.findIndex(i => i.companyAnalysesItemId === newCompanyAnalysesItem.companyAnalysesItemId);
        if (itemIndex !== -1) {
          state.companyAnalysesType[index].companyAnalysesItems[itemIndex] = newCompanyAnalysesItem;
        }
        else{
          console.log('hay un error 2');
        }
      }
      else{
        console.log('hay un error 1');
        
      }

    },

    deleteCompanyAnalysesItem(state, companyAnalysesItemId) {
      state.companyAnalysesType.forEach(cAT => {
        cAT.companyAnalysesItems = cAT.companyAnalysesItems.filter(item => item.companyAnalysesItemId !== companyAnalysesItemId);
      })
    },
  },
  actions: {
    async obtainCompanies({ commit }) {
      const companies = await getCompanies();
      commit('setCompanies', companies);
    },
    async obtainFilteredCompanies({ commit }, { totalDocuments, hasPriority, totalPrograms }) {
      const companies = await getFilteredCompanies(totalDocuments, hasPriority, totalPrograms);
      commit('setCompanies', companies);
    },

    async obtainCompaniesWithTotalsByTopicId({ commit }, {topicId, totals, byTopic}){
      const companies = await getCompaniesWithTotalsByTopicId(topicId, totals, byTopic);
      commit('setCompanies', companies);
    },

    async obtainCompanyTypes({ commit }) {
      const companyTypes = await getCompanyTypes();
      commit('setCompanyTypes', companyTypes);
    },
    async obtainCompaniesByCompanyId({ commit }, companyId) {
      const companies = await getCompanyByCompanyId(companyId);
      // commit('setCompanies', companies);
      commit('setCompany', companies);
    },

    async obtainFilteredCompaniesByTopicIdWithTotalsByTopicId({ commit }, {topicId, totals, byTopic} ){
      const companies = await getCompaniesWithTotalsByTopicId(topicId, totals, byTopic);
      commit('setCompaniesPerTopic', companies);
    },

    async obtainCompaniesByTopicId({ commit }, topicId) { //  OBTAINCOMPANIESBYTOPICID
      const companies = await getCompaniesByTopicId(topicId);
      commit('setCompaniesPerTopic', companies);
    },
    obtainCompaniesByTopicIdNotFound({ commit }) { // OBTAINCOMPANIESBYTOPICIDNOTFOUND
      commit('setCompaniesPerTopic', []);
    },

    //
    async addNewCompany({ commit }, company) {
      const response = await createCompany(company);
      commit('addCompany', response.data);
    },
    async modifyCompany({ commit }, company) {
      const response = await editCompany(company.companyId, company);
      commit('updateCompany', response.data);
    },
    async removeCompany({ commit }, companyId) {
      await deleteCompany(companyId);
      commit('deleteCompany', companyId);
    },
    async obtainContractsPerCompany({ commit }, topicId) {
      const contractPerCompany = await getContractsPerCompany(topicId);
      commit('setContractPerCompany', contractPerCompany);
    },
    cleanContractsPerCompany({commit}){
      commit('cleanContractPerCompany')
    },

    //companySort
    updateCompanySort({ commit }, companySort) {
      commit('updateCompanySort', companySort)
    },

    //topicCompanySort
    updateTopicCompanySort({ commit }, topicCompanySort) {
      commit('updateTopicCompanySort', topicCompanySort)
    },

    // For create a document wit company Selected
    updateCompanyForCreateDocument({ commit }, companyId) {
      commit('updateCompanyForCreateDocument', companyId);
    },

    resetUpdateCompanyForCreateDocument({ commit }) {
      commit('resetUpdateCompanyForCreateDocument');
    },

    // FOR CREATE A PROGRAM WITH MANUFACTURED SELECTED
    updateCompanyForCreateProgram({ commit }, companyId) {
      commit('updateCompanyForCreateProgram', companyId)
    },

    resetUpdateCompanyForCreateProgram({ commit }) {
      commit('resetUpdateCompanyForCreateProgram')
    },

    // SWOT
    async obtainSwotCompanyAnalysesType({ commit }, companyId) {
      const companyAnalysesType = await getCompanyAnalysesType(companyId);
      commit('setSwotCompanyAnalysesType', companyAnalysesType);
    },

    cleanSwotCompanyAnalysesType({ commit }) {
      commit('cleanSwotCompanyAnalysesType');
    },
    // SWOT ITEMS
    // addCompanyAnalysesItem
    async addCompanyAnalysesItems({ commit }, SWOTForm) {
      const companyAnalysesItem = await addCompanyAnalysesItems(SWOTForm);
      // console.log(companyAnalysesItem);
      commit('addCompanyAnalysesItems', { companyAnalysesItem: companyAnalysesItem.data, companyAnalysesTypeId: SWOTForm.companyAnalysesTypeId });
    },

    async editCompanyAnalysesItems({commit}, {form, companyAnalysesItemId}){
      const companyAnalysesItem = await editCompanyAnalysesItems(form, companyAnalysesItemId);
      commit('editCompanyAnalysesItems',{companyAnalysesItem} )
    },

    // deleteCompanyAnalysesItem
    async deleteCompanyAnalysesItem({ commit }, SWOTItemId) {
      await deleteCompanyAnalysesItems(SWOTItemId);
      commit('deleteCompanyAnalysesItem', SWOTItemId);
    },

  },
};

export default companyModule;
